<template>

  <div class="screen singe-job job" v-if="job">


      <div class="jobheader">
        <div class="jobheader__left" @click="$router.go(-1)">
          <Svgicon icon="icons/chevron_left"/>
        </div>
        <div class="jobheader__right">
          <h2>#{{job.reference}}</h2>
          <p>Modtaget {{timeSince(job.timestamp)}}.</p>
        </div>
      </div>

  <div class="job__layout">
     
    <section class="job__left">
    

      <div class="job__flex">
        <p>Beskrivelse</p>
        <div class="job__box">
          <div class="job__description">
            <h3>{{dotdotdot(job.title)}}</h3>
            <br>
            <p class="blur">Lorem ipsum dolor sit amet consectetur adipisicing elit. Sed veniaes, natus quasi, ducimus deserunt maiores laborum tempora adipisci unde dolores quidem voluptatibus fugiat sequi placeat possimus? Fugit, dolorum!
             Animi mollitia minus dolor quas molestias saepe beatae eligendi, <br><br>est fugit aperiam adipisci eaque repudiandae consequatur quis ipsa.</p>
          </div>
        </div>
      </div>
      
      <hr>

      <div class="job__flex">
        <p>Billeder</p>
        <div class="job__box">
          
            <div class="job__images">
              <div v-for="image in images">
              <img  class="doubleblur" draggable="false" :src="image" alt="">
              </div>
            </div>
        </div>
      </div>

      <hr>

      <div class="job__flex">
        <p>Noter</p>
        <div class="job__box">
          <div class="job__note">
            <p>Du har ingen noter på denne opgave</p>
            <button class="btn btn--disabled">Tilføj ny note</button>
          </div>
        </div>
      </div>

      <hr>

      <div class="job__flex">
        <p>Opgavestatus</p>
        <div class="job__box">
          <div class="job__steps">
            <div class="step">
              <div class="step__dot dot--filled"></div>
              <div class="step__info">
                <h4>Opgaven er modtaget</h4>
                <p>{{formatDate(job.timestamp)}}</p>
              </div>
            </div>
            <div class="step">
              <div class="step__dot dot--filled"></div>
              <div class="step__info">
                <h4>Opgaven er verificeret og godkendt</h4>
                <p>{{formatDate( yesterday )}}</p>
              </div>
            </div>
            <div class="step">
              <div class="step__dot"></div>
              <div class="step__info">
                <h4>Bygherre er kontaktet</h4>
              </div>
            </div>
          </div>
        </div>
      </div>

    
      <hr>

    </section>

    <section class="job__right">
      <div class="job__boxright">
        <img class="job__cover" :src="image(job)" alt="">
        <div class="job__content">
          <h4>Bygherrer oplysninger</h4>
          <br>

          <div class="job__contact">
            <Svgicon icon="icons/person"/><span class="blur">Anders Andersen</span>
          </div>
          <div class="job__contact">
            <Svgicon icon="icons/location"/><span class="blur">Robert Jacobsens vej <br> 2300 københavn S</span>
          </div>
          <br>

          <button class="btn btn--green btn--center btn--full" @click="trigger_restricted_access">Kontakt bygherre</button>

        </div>
      </div>
    </section>
  </div>

</div>

<div v-else>
  <div class="screen singe-job job fourofour">
    <div class="job__dontexist">
      <h2>Opgaven er lukket.</h2>
      <br>
        <p>En dygtig håndværker har allerede lavet en aftale med bygherre, opgaven er derfor ikke tilgængelig længere.</p>
        <br>
        <router-link :to="'/opgaver/' + this.$route.hash" class="btn">Se nye opgaver</router-link>
    </div>
  </div>
  

</div>
      
</template>


<script>

//import AwaitingJobs from "@/components/AwaitingJobs";

export default {
  components: {
    //AwaitingJobs,
  },

  data() {
      return {

      }
  },

  computed: {

      job() {
          if( this.$route.params.ref ) {
              return this.$store.getters['getJobByReference'](this.$route.params.ref)
          }
          return null
      },

      yesterday() {
        let date = new Date(this.job.timestamp)
        date.setHours(date.getHours() - 8);
        return date;
      },

      images() {

        let ref = this.$route.params.ref
        let lastNumRef = ref.substr(ref.length - 1)

        // Add 3-4 images counting from lastNumRef
        // If more that twelve, cirlce back to 1
        let images = []
        let counter = 4
        if( lastNumRef % 2 ) counter = 3
        for(let i = 0; i < counter; i++) {
          let num = (lastNumRef * 1) + i
          if(num > 12) {
            num = num - 12
          }
          images.push( require(`@/assets/img/img${num}.png` ) )
        }
        return images;   
      }
  },

  methods: {

    dotdotdot(title) {
      if( title.length > 49) {
          return title + '...'
      }
      return title;
    },

    image(job) {

        if( job.energyLabelPictureUrl !== '') {
            return job.energyLabelPictureUrl
        } else if( job.gmapsStreetViewImageUrl !== '') {
            return job.gmapsStreetViewImageUrl
        }
        return require( "@/assets/img/placeholder_img.png")   
    },

    trigger_restricted_access() {
      this.$store.dispatch('limited_access_toggle', true)
    },
  },

};
</script>


<style scoped lang="scss">


.jobheader {
  display: flex;
  flex-direction: row;
  gap: 20px;
  &__left {
    cursor: pointer;
    width: 40px;
    height: 40px;
    background: white;
    padding: 10px;
    border-radius: 10px;
    box-shadow: $shadow1;
  }
}

.job {
  margin-top: 20px;

  &__layout {
    display: flex;
    gap: 2rem;
    @include large {
      flex-direction: column-reverse;
    }
  }
  
  &__left {
      width: 100%;
  }
  &__right {
    flex: 0 0 300px;
    @include extralarge_up {
      margin-top: 2rem;
      flex: 0 0 350px;
    }
    @include extralarge {
      margin-top: 3.5rem;
    }
    @include mobile {
      margin-top: 1.5rem;
    }
  }

  &__contact {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    margin-bottom: 1rem;
    .svgicon {
      width: 40px;
      height: 40px;
      background: white;
      padding: 9px;
      border-radius: $radius;
      box-shadow: $shadow1;
    }
  }

  &__flex {
    display: flex;
    flex-direction: row;
    margin: 2rem 0;
    > p {
      font-weight: bold;
      flex: 0 0 200px;
    }

    @include extralarge {
      flex-direction: column;
      > p {
        flex: auto;
      }
    }
  }

  &__box {
    background: white;
    padding: 3rem 2rem;
    border-radius: $radius;
    box-shadow: $shadow2;
    flex: auto;
  }

  &__description {
      max-width: 500px;
  }

  &__note {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  

  &__images {
      display: flex;
      gap: 30px;
      flex-wrap: wrap;
      > div {
        overflow: hidden;
        border-radius: $radius
      }
      img {
        width: 160px;
        border-radius: $radius;
        user-select: none;
        @include small {
          width: 100px;
        }
      }
  }

  &__boxright {
    background-color: white;
    border-radius: $radius;
    box-shadow: $shadow2;
    overflow: hidden;
    position: sticky;
    top: 100px;
    @include large {
      display: flex;
      position: relative;
      top: 0;
      > * {
        width: 50%;
      }
    }
    @include mobile {
      flex-direction: column;
      > * { width: 100%; }
    }
  }

  &__cover {
    object-fit: cover;
    object-position: center;
    width: 100%;
  }
  &__content {
    padding: 2rem;
  }
}


.job__steps {
  display: flex;
  flex-direction: column;
  gap: 50px;
  margin-bottom: 1rem;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    left: 20px;
    top: 5px;
    height: 90%;
    width: 2px;
    background: $color12;
  }

  .step {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 30px;
    .step__dot {
      z-index: 1;
      position: relative;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: white;
      border: 2px dashed $color12;
    }

    .dot--filled {
      background: $color12;
      border: 2px solid $color12;
    }

    .step__info {
      p {
        font-size: 0.9rem;
        opacity: 0.7;
      }
    }
  }
}


.fourofour {
      min-height: calc( 100vh - 150px);
}
.job__dontexist {
  background: white;
  margin: 0 auto;
  padding: 3rem;
  box-shadow: $shadow2;
  max-width: 550px;
  text-align: center;
}


.blur {
      filter: blur(4px);
      user-select: none;
}

.doubleblur {
  filter: blur(12px);
  user-select: none;
}


</style>